import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const ROUTER_PATH = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  HOME_PAGE: "/",
  PATIENT_DIAGNOSIS: "/patient-diagnosis",
  THANK_YOU: "/thank-you",
  VITALS_MONITORING: "/vitals-monitoring",
  START_CHAT: "/start-chat",
  CHAT: "/chat",
  CHAT_HISTORY: "/chat-history",
  CHAT_DETAILS: "/chat-details",
  REPORT: "/report",
  INDIVIDUAL_SESSION: "/individual-session",
  SUBSCRIPTION: "/subscription",
  Dashboard: "/dashboard",
  Dashboard_Find_Doctor: "/dashboard/find-a-doctor",
  Dashboard_My_Appointments: "/dashboard/my-appointments",
  Dashboard_Home: "/dashboard/home",
  FORGOT_PASSWORD: "/auth/forgot-password",
  VERIFY_OTP: "/auth/verify-otp",
  RESET_PASSWORD: "/auth/reset-password",

  // STEPS
  WELCOME: "/welcome",
  PRIMARY_GOAL: "/welcome/primary-goal",
  HEALTH_DETAILS: "/welcome/health-details",
  HEALTH_PROFILE: "/health-profile",
  UPDATE_HEALTH_PROFILE: "/health-profile/:id",
};

// dev env
// const BASE_URL = "https://medistreamlinebe.xeventechnologies.com";
// const BE_URL = "https://medistreamline-be.xeventechnologies.com/api";
// export const stripePromiseKey = loadStripe(
//   "pk_test_51PCGskLxJGatvhqPuaSRnd6B0JBY1sjsm7K6m9XSw10BOXWMwzoaEMnmhuaAIXHelUETYiFcviSEMD7h5lYTn1kg00m9LJcWEB"
// );

// prod env
const BASE_URL = "https://aibe.medistreamline.ai";
const BE_URL = "https://api.medistreamline.ai/api";
export const stripePromiseKey = loadStripe(
  "pk_live_51PCGskLxJGatvhqPpXuwk4AKHXtsGkKvsf3y0auBYMdEHFKPqsJeoNsGSOHh2Wwusr2DuFDUiFApvP0ZVS6Qhf2A00TMG9yLG1"
);

const token = JSON.parse(localStorage.getItem("med_user"))?.token;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
if (token) headers.Authorization = `Bearer ${token}`;

export const api = axios.create({
  baseURL: BE_URL,
  headers,
});

const API_URLS = {
  BLOB_STORAGE: {
    UPLOAD_ATTACHMENT: BASE_URL + "/ImageDiagnosis/",
  },
  USER: {
    GENERATE_ID: BASE_URL + "/generateUserId",
    SIGN_UP: BE_URL + "/auth/registration",
    // SIGN_UP: BE_URL + "/patientRegistration/registration",
    SIGN_IN: BE_URL + "/auth/login",
    SOCIAL_MEDIA_LOGIN: BE_URL + "/auth/SocialMediaLogin",
    VERIFY_ACCOUNT: BE_URL + "/auth/VerifyAccount",
  },

  MESSAGE: {
    SAVE: BE_URL + "/message/save",
    GET: BE_URL + "/message/getAll",
    CHAT_IMAGE_COUNT: BE_URL + "/message/getImageCount",
  },
  CHAT: {
    WELCOME: BASE_URL,
    PROMPT_RESPONSE: BASE_URL + "/qnaConversation",
    AUDIO_FROM_TEXT: BASE_URL + "/text_to_speech",
    SAVE_USER_CHAT: BASE_URL + "/user/save_history_name/",
    GET_CHAT_HISTORY: BASE_URL + "/get_chat_history",
    GENERATE_CHAT_NAME: BASE_URL + "/generate_chat_name",
    GENERATE_CHAT_REPORT: BASE_URL + "/generate_report",

    GET_CHAT: BE_URL + "/chat/getAll",
    SAVE_HISTORY: BE_URL + "/chat/save",
    DOCTOR_PROFILE: BE_URL + "/doctorProfile/getAll",
    END_SESSION: BE_URL + "/chat/isSessionEnd/",
  },

  VITALS_MONITORING: {
    GET_BLOOD_PRESSURE: BASE_URL + "/blood_pressure_random",
    GET_BLOOD_OXYGEN: BASE_URL + "/blood_oxygen_random",
    GET_PULSE_RATE: BASE_URL + "/pulse_rate_random",
    GET_TEMPERATURE: BASE_URL + "/temperature_random",
    GET_GLUCOSE: BASE_URL + "/glucose_random",
    GET_WEIGHT: BASE_URL + "/weight_random",
  },

  SUBSCRIPTION: {
    CREATE: "/subscription/createSubscription",
    CHECK: "/subscription/checkInvoicePayment/",
    GET_ALL_PLAN: BE_URL + "/plan/getAll",
    CANCEL_SUBSCRIPTION: BE_URL + "/subscription/cancelSubscription/",
    UPDATE_SUBSCRIPTION: BE_URL + "/subscription/updateSubscription",
  },

  PATIENT_PROFILE: {
    GET: BE_URL + "/patientAssessment/get",
    CREATE: BE_URL + "/patientAssessment/save",
    UPDATE: BE_URL + "/patientAssessment/update",
  },

  ADMIN_DASHBOARD: {
    GET: BE_URL + "/adminDashboard/dashboardCard",
    GET_PATIENT_GRAPH: BE_URL + "/adminDashboard/getPatientRegisterationGraph",
    GET_SUBSCRIPTION_GRAPH: BE_URL + "/adminDashboard/getSubscriptionPlanGraph",
  },

  LOOKUP: {
    GET_LOOKUP: BE_URL + "/lookup/getAll",
  },

  AUTH: {
    SEND_OTP: BE_URL + "/auth/sendOTP",
    VERIFY_OTP: BE_URL + "/auth/verifyOTP",
    RESET_PASSWORD: BE_URL + "/auth/resetPassword",
  },
};

const API_KEYS = {
  GOOGLE_CLIENT_Id:
    "724428639505-smu4f0ksiink838qhjo13jl10lo34hd1.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "473755715237615",
  GOOGLE_MAP_API_KEY: "AIzaSyB5SU-oTgznusbNQsFtcfKnmP0czak3Zcg",
};

export { API_URLS, BASE_URL, ROUTER_PATH, API_KEYS };
